import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from '@theme/layout/admin/admin.component';
import {GuestComponent} from "@theme/layout/guest/guest.component";

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./demo/dashboard/dashboard.component'),
      },
      {
        path: 'basic',
        loadChildren: () =>
          import('./demo/ui-elements/ui-basic/ui-basic.module').then(
            (m) => m.UiBasicModule,
          ),
      },

      {
        path: 'roles',
        loadChildren: () =>
          import('./demo/pages/role/role.module').then(
            (m) => m.RoleModule,
          ),
      },
      {
        path: 'companies',
        loadChildren: () =>
          import('@pages/company/company.module').then(
            (m) => m.CompanyModule,
          ),
      },
      {
        path: 'permissions',
        loadChildren: () =>
          import('./demo/pages/permission/permission.module').then(
            (m) => m.PermissionModule,
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./demo/pages/user/user.module').then(
            (m) => m.UserModule,
          ),
      },
    ],
  },
  {
    path: '',
    component: GuestComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./demo/pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule,
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
