import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  permission?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/dashboard',
    icon: 'assets/new-icons/dashboards.svg',
    classes: 'nav-item',
  },
  {
    id: 'users',
    title: 'Users',
    type: 'item',
    url: '/users',
    icon: 'assets/new-icons/staff.svg',
    classes: 'nav-item',
  },
  {
    id: 'roles',
    title: 'Roles',
    type: 'item',
    url: '/roles',
    icon: 'assets/new-icons/roles.svg',
    classes: 'nav-item',
  },
  {
    id: 'permissions',
    title: 'Permissions',
    type: 'item',
    icon: 'assets/new-icons/permissions.svg',
    url: '/permissions',
    classes: 'nav-item',
  },
  {
    id: 'companies',
    title: 'Companies',
    type: 'item',
    url: '/companies',
    icon: 'assets/new-icons/companies.svg',
    classes: 'nav-item',
  },

];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
